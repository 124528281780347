import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import EmployeeTestimonialItem from "./EmployeeTestimonialItem";
import GlassdoorReview from "./GlassdoorReview";

const EmployeeTestimonialGrid = () => {
    const employeeTestimonials = useStaticQuery(graphql`
        {
            allContentfulTestimonials(
                filter: { testimonialType: { eq: true } }
                sort: { fields: createdAt, order: DESC }
            ) {
                nodes {
                    id
                    personDesignation
                    personName
                    profileImage {
                        gatsbyImageData(
                            quality: 100
                            sizes: "250"
                            layout: CONSTRAINED
                            placeholder: BLURRED
                        )
                        title
                    }
                    testimonialType
                    testimonialStatement {
                        testimonialStatement
                    }
                }
            }
        }
    `);

    return (
        <div className="a-voices-card-wrp">
            {employeeTestimonials.allContentfulTestimonials.nodes.map((t) => (
                <EmployeeTestimonialItem
                    key={t.id}
                    quote={t.testimonialStatement.testimonialStatement}
                    avatar={t.profileImage}
                    name={t.personName}
                    designation={t.personDesignation}
                />
            ))}
            <GlassdoorReview />
        </div>
    );
};

export default EmployeeTestimonialGrid;
