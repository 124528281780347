import React from "react";
import Layout from "../../components/Layout";
import Lottie from "react-lottie";
import * as Innovation from "../../animations/Innovation.json";
import * as Freedom from "../../animations/Freedom.json";
import * as Empowerment from "../../animations/Empowerment.json";
import * as Trust from "../../animations/Trust.json";
import CareerSlider from "../../components/CareerSlider";
import EmployeeTestimonialGrid from "../../components/EmployeeTestimonialGrid";
import Seo from "../../components/seo";
import ogIMage from "../../images/og.png";

export default function opportunities({ location }) {
    return (
        <Layout>
            <Seo
                title="Careers - Sedin Technologies"
                description={
                    "Explore exciting career opportunities to reach the next level. Connect with the best team you will ever be a part of. Make a difference by doing what you do best"
                }
                url={location.href}
                image={`https://sedintechnologies.com${ogIMage}`}
            />
            <section className="opportunities-hero">
                <div className="container container--first flex flex-ai-c">
                    <div className="hero-text">
                        <p className="text-p1 text-fw-medium text-clr-secondary gap-3x">
                            Work with us
                        </p>
                        <h1 className="text-h2 text-fw-medium text-clr-primary">
                            Discover yourself at Sedin. Explore opportunities across divisions. Work
                            with the finest professionals from around the world in a space that
                            elevates your skills.
                        </h1>
                    </div>
                </div>
            </section>
            <section className="voices">
                <div className="container container--py">
                    <div className="voices-title">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-4x">
                            Voices and vignettes.
                        </h2>
                    </div>
                    <EmployeeTestimonialGrid />
                </div>
            </section>
            <section className="work-culture">
                <div className="container container--py">
                    <div className="text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            Move from zero to one. All in a day’s work.
                        </h2>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                            Here’s what drives our work culture.
                        </p>
                    </div>
                    <div className="work-grid">
                        <div className="work-item">
                            <div className="a-animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: Innovation,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Innovation
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    We encourage inspired, entrepreneurial and innovative thinking.
                                    We’ve built an environment where people can learn, evolve,
                                    experiment, and fail without fear.
                                </p>
                            </div>
                        </div>
                        <div className="work-item">
                            <div className="a-animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: Freedom,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Freedom
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    The future of work is borderless, dynamic, and remote. Our
                                    processes allow team members to collaborate from all over the
                                    world, driven by a singular vision.
                                </p>
                            </div>
                        </div>
                        <div className="work-item">
                            <div className="a-animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: Empowerment,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x ">
                                    Empowerment
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    At Sedin, we’re committed to the personal growth of all members.
                                    We invest dedicated hours for learning and development to
                                    upskill teams, across divisions.
                                </p>
                            </div>
                        </div>
                        <div className="work-item">
                            <div className="a-animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: Trust,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Trust
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Our culture of transparency cuts hierarchies and builds trust.
                                    Every voice matters, so we involve people in key decisions and
                                    encourage consistent feedback.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home-career">
                <div className="container container--last">
                    <div className="text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-4x">
                            Explore all open positions.
                        </h2>
                    </div>
                    <div className="career-slider-wrapper">
                        <CareerSlider></CareerSlider>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
