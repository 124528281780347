import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import StarIcon from "./StarIcon";

const GlassdoorReview = () => {
    return (
        <div className="a-voices-card">
            <StaticImage
                src="../images/glassdoor.png"
                class="gap-1x logo-glassdoor"
                placeholder="blurred"
                alt="glassdoor"
                quality="100"
            />
            <div className="flex gap-5x">
                <p className="text-p1 a-glassdoor-rating text-fw-medium">4.5</p>
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon type="half" />
            </div>
            <p className="text-p2 a-glassdoor-card-cnt text-fw-regular text-clr-secondary">
                Don’t take our word for it — read the reviews yourself.
            </p>
            <a
                href="https://www.glassdoor.com/Reviews/Sedin-Technologies-Reviews-E382372.htm"
                target="_blank"
                rel="noreferrer"
                className="sdn-link"
            >
                Visit Glassdoor
            </a>
        </div>
    );
};

export default GlassdoorReview;
