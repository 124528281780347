import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const EmployeeTestimonialItem = ({ quote, name, designation, avatar }) => {
    return (
        <div className="a-voices-card">
            <div className="a-rounded gap-2x avatar">
                <GatsbyImage image={avatar.gatsbyImageData} alt={avatar.title} />
            </div>
            <p className="text-p2 text-fw-regular text-clr-secondary gap-2x">{quote}</p>
            <p className="text-p3 text-fw-regular text-clr-primary a-gap-1rem">{name}</p>
            <p className="text-p5 text-fw-regular  text-clr-secondary">{designation}</p>
        </div>
    );
};

export default EmployeeTestimonialItem;
