import React from "react";

const StarIcon = ({ type = "full" }) => {
    if (type === "full") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34.56"
                height="32"
                viewBox="0 0 34.56 32"
            >
                <defs>
                    <style>{`.c{fill:#0dab40;}`}</style>
                </defs>
                <path
                    className="c"
                    d="M17.28,0l4.061,12.248,13.219-.025L23.851,19.768,27.96,32,17.28,24.415,6.6,32l4.109-12.232L0,12.223l13.219.025Z"
                />
            </svg>
        );
    }
    if (type === "half") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34.56"
                height="32"
                viewBox="0 0 34.56 32"
            >
                <defs>
                    <style>{".a{fill:#ddd;}.b{fill:#0dab40;}"}</style>
                </defs>
                <g transform="translate(-284.2)">
                    <path
                        className="a"
                        d="M301.481,375.582l4.061,12.247,13.218-.025-10.709,7.544,4.109,12.231L301.481,400,290.8,407.58l4.109-12.231L284.2,387.8l13.219.025Z"
                        transform="translate(0 -375.58)"
                    />
                    <path
                        className="b"
                        d="M301.479,375.58v24.415L290.8,407.578l4.112-12.231L284.2,387.8l13.223.024Z"
                        transform="translate(0 -375.58)"
                    />
                </g>
            </svg>
        );
    }
};

export default StarIcon;
